import React from "react";
import Info from "../component/user/info";
import Header from "../component/header";
import Footer from "../component/footer";

const User = () =>{
    return (
        <>
            <Header />
            <Info />
            <Footer />
        </>
    )
}

export default User